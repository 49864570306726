[data-amplify-form],
[data-amplify-footer] {
  background-color: var(--application-color-foreground);
  color: var(--application-color-background);
  padding-bottom: 8px !important;
}

[data-amplify-footer] {
  display: flex;
  justify-content: flex-start;
  margin-left: 32px;
}

[data-amplify-router] {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

input.amplify-input.amplify-field-group__control {
  border-color: var(--application-color-background);
  /* color: black; */
  color: #29373b;
  background-color: #d7d9d6 !important;
}

/* This is both the Sign in button and Forgot your password? button */
.amplify-button {
  background-color: var(--application-color-foreground);
  border-color: var(--application-color-active);
  /* color: var(--application-color-active); */
}

/* This is the Sign in button */
.amplify-button.amplify-button--primary {
  background-color: #ff6a00;
  border-color: transparent;
  color: white;
  margin-top: 16px;
}

/* This is the Forgot your password? button */
.amplify-button.amplify-button--link {
  color: #ff6a00;
  font-size: medium;
  font-weight: 600 !important;
  margin-bottom: 18px;
  padding-left: 0px;
  padding-right: 0px;
}

.amplify-button:hover {
  background-color: var(--application-color-active);
  color: var(--application-color-foreground);
}

.amplify-button.amplify-button--primary:hover {
  background-color: #dc5c01;
  border-color: transparent;
  color: white;
  margin-top: 16px;
}

.amplify-button.amplify-button--link:hover {
  color: #dc5c01;
  font-size: medium;
  font-weight: 600 !important;
}

.amplify-field-group__field-wrapper {
  background-color: #d7d9d6;
  border-radius: 4px;
}

.amplify-passwordfield .amplify-field-group__field-wrapper {
  border-radius: 4px 0px 0px 4px;
}

.amplify-input,
.amplify-input:focus {
  /* border-color: var(--application-color-active); */
  border-color: transparent !important;
  /* background-color: var(--application-color-foreground); */
  box-shadow: none;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-color: var(--application-color-foreground);
}

[data-amplify-router-content] {
  border-radius: 16px;
}

.amplify-field__show-password,
.amplify-field__show-password:focus {
  /* background-color: var(--application-color-background); */
  background-color: #d7d9d6;
  border-color: transparent !important;
  border: 0;
  box-shadow: none;
}

.amplify-field__show-password:enabled {
  /* background-color: var(--application-color-background);
  color: var(--application-color-foreground); */
  color: black;
  font-size: x-large;
  background-color: #d7d9d6;
  /* border-color: var(--application-color-foreground); */
  border-color: transparent;
  border: 0;
}

.amplify-heading {
  color: var(--application-color-background);
}
.amplify-label {
  color: var(--application-color-background);
  font-size: small;
  font-weight: 600;
}

/* react-toastify override */
.Toastify__toast {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.Toastify__toast--success,
.Toastify__toast--error {
  padding: 0;
}
