.power-supplier-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70vw;
  height: fit-content;
  border-radius: 8px;
  background-color: white;
}

.power-suppler-form-top-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* height: 100px; */
  background-color: #f0f0f3;
  padding-left: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.MuiButton-text.power-supplier-form-back-button:hover {
  background-color: transparent;
}

.power-supplier-form-top-middle-container {
  display: flex;
  flex-direction: column;
  width: 708px;
}

.power-supplier-form-top-right-container {
  width: 110px;
}

.power-supplier-form-content-container {
  width: 708px;
  height: 100%;
}

.power-supplier-form {
  width: 100%;
  margin: 16px 0px 16px;
}

.power-supplier-form-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d0d2d8;
  width: 100%;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.power-supplier-form-bottom {
  background-color: #f0f0f3;
  padding: 24px 16px 24px 16px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.utility-company-input > .MuiInputBase-input,
.utility-info-input > .MuiInputBase-input,
.error-utility-company-input > .MuiInputBase-input,
.error-utility-info-input > .MuiInputBase-input {
  padding: 0;
}

.power-supplier-input-label-container {
  display: flex;
  align-items: center;
}

.utility-company-search-results-container {
  position: absolute;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px #d0d2d8 solid;
  border-radius: 8px;
  max-height: 184px;
  overflow-y: auto;
  width: 676px;
}

.MuiButton-text.utility-company-search-result-button:hover {
  background-color: white;
}

.MuiMenuItem-root.utility-info-option {
  font-family: 'Manrope', sans-serif;
}

.utility-company-input::before,
.utility-company-input::after,
.error-utility-company-input::before,
.error-utility-company-input::after,
.utility-info-input::before,
.utility-info-input::after,
.error-utility-info-input::before,
.error-utility-info-input::after {
  border: 0 !important;
}

.error-utility-company-input,
.error-utility-info-input {
  /* border: 1px #ff5c77 solid !important; */
  border: 1px #e35050 solid !important;
}

.MuiInputBase-root.kws-info-select {
  border-radius: 8px;
}

#mui-component-select-kwsInfo {
  border: none;
}

.MuiButton-text.power-supplier-form-save-button,
.MuiButton-text.power-supplier-form-save-button:hover {
  background-color: #ff6a00;
  width: 154px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}
