/* Deal List */
.deal-list-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding-top: 178px;
  /* padding-top: 90px; */
}

.deal-list-title {
  border-radius: 30px;
  padding: 10px;
}

.MuiTypography-body1.deal-title {
  padding-top: 44px;
  font-size: 36px;
  font-weight: 700;
  color: #222428;
}

.deal-title-and-add-deal-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  min-width: 450px;
  margin-right: 61px;
  margin-bottom: 32px;
  border-radius: 8px;
}

.MuiButton-text.add-deal-button {
  display: flex;
  justify-content: space-evenly;
  background-color: #ff6a00;
  color: white;
  font-size: 18px;
  font-weight: 700;
  width: 154px;
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  gap: 10px;
}

.MuiButton-text.add-deal-button:hover {
  background-color: #ff6a00;
  /* box-shadow: 0px 3px 8px #827ffc !important; */
  cursor: pointer;
}

.add-deal-button-skeleton {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ff6a00;
  color: white;
  width: 154px;
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
}

/* .add-deal-button-skeleton-wrapper {
  border-radius: 8px;
  overflow: hidden;
  height: fit-content;
  width: fit-content;
} */

.add-deal-menu-container,
.sort-menu-container,
.option-menu-container {
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 24px 0px #ababab5e;
}

.add-deal-menu-container,
.sort-menu-container {
  z-index: 2;
}

.option-menu-container {
  z-index: 10001;
}

.option-menu-container-background {
  position: fixed;
  /* top: 0px; */
  bottom: 0px;
  left: 0px;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}

.add-deal-menu-container {
  top: 160px;
  width: 228px;
}

.add-deal-menu-first-container,
.add-deal-menu-middle-container,
.add-deal-menu-last-container,
.sort-menu-first-container,
.sort-menu-middle-container,
.sort-menu-last-container,
.option-menu-edit-container,
.option-menu-change-stage-container,
.option-menu-schedule-container,
.option-menu-delete-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.add-deal-menu-first-container,
.sort-menu-first-container {
  border-radius: 12px 12px 0px 0px;
}

.add-deal-menu-last-container,
.sort-menu-last-container {
  border-radius: 0px 0px 12px 12px;
}

.add-deal-menu-first-container,
.add-deal-menu-middle-container {
  border-bottom: 0.5px solid #11111140;
}

.MuiButton-text.add-deal-menu-button {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 16px 12px 16px;
  border-radius: 12px;
}

.MuiButton-text.add-deal-menu-button:hover {
  cursor: pointer;
  background-color: white;
  border-radius: 12px;
}

.MuiTypography-body1.add-deal-menu-text {
  color: black;
  margin-left: 8px;
}

.deal-list-overlay-left {
  overflow-y: auto;
  overflow-x: auto;
  width: 45%;
  /* background: var(--component-background); */
  background: #f0f0f3;
  /* border-radius: 30px; */
  padding-left: 61px;
  /* padding-left: 15% */
  min-height: 430px;
}

.lead-search-overlay {
  min-width: 450px;
  margin-right: 61px;
  margin-bottom: 5px;
  /* padding-top: 20px; */
  border-radius: 8px;
  background: white;
}

.lead-search-overlay .MuiFormControl-root {
  width: 100% !important;
}

.MuiInputBase-root.MuiInputBase-adornedStart {
  padding-left: 4px !important;
}

.MuiInputBase-root.MuiInputBase-adornedEnd {
  padding-right: 4px !important;
}

#leadSearch.MuiInputBase-input {
  border-radius: 8px;
  background-color: white;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 22px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#leadSearch.MuiInputBase-input::placeholder {
  color: black !important;
  opacity: 1;
}

.leadSearch-skeleton-container {
  border: 1px #d0d2d8 solid;
  border-radius: 8px;
  background-color: white;
  padding: 12px;
}

.deal-list-settings-overlay {
  display: grid;
  /* below rule set in DealsList.js so columns can be dynamic! */
  /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
  column-gap: 16px;
  /* flex-direction: row;
  justify-content: space-evenly; */
  min-width: 450px;
  margin-top: 22px !important;
  margin-bottom: 2px !important;
  margin-right: 61px;
  padding: 10px 0px 10px 0px !important;
}

.deal-list-settings-container {
  display: grid;
  /* max-width: 157px; */
}

.deal-list-settings-container > .MuiInputBase-root {
  border-radius: 8px !important;
  height: 50px !important;
}

.deal-list-settings-container .scorecard-dropdown-box {
  padding: 0px !important;
  height: 50px;
  border: none !important;
  border-radius: 8px !important;
  color: black !important;
  background-color: white !important;
  /* stroke: rgba(0, 0, 0, 0.54) !important;
  fill: rgba(0, 0, 0, 0.54) !important; */
  /* NOTE: rgb(117, 117, 117) is the same as rgba(0, 0, 0, 0.54) buut the border and fill overlap resulted in a thin black inner border on icons because the opacities added together to just be black */
  stroke: rgb(117, 117, 117) !important;
  fill: rgb(117, 117, 117) !important;
  /* max-width: 157px !important; */
}

.deal-list-settings-container
  .MuiAutocomplete-root
  .MuiFormControl-root
  > .scorecard-dropdown-box:hover
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d0d2d8 !important;
}

.MuiAutocomplete-popper
  .MuiPaper-root
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option:hover {
  background-color: #fac8b2;
}

.MuiAutocomplete-popper
  .MuiPaper-root
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option.Mui-focused[aria-selected='true'] {
  background-color: #f0f0f3 !important;
}

.MuiAutocomplete-popper
  .MuiPaper-root
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option.Mui-focused[aria-selected='true']:hover {
  background-color: #f0f0f3 !important;
}

.deal-list-settings-container .scorecard-dropdown-box > fieldset {
  border-radius: 8px !important;
}

.deal-list-settings-container .scorecard-dropdown-box > fieldset:hover {
  border: none !important;
}

#mui-component-select-dealType {
  padding: 12.5px 32px 12.5px 12px;
}

.MuiInputBase-root.scorecard-dropdown-box
  > .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 12px 32px 12px 12px;
}

.deal-list-settings-container .MuiAutocomplete-root {
  width: 100%;
}

.MuiAutocomplete-popper > .autocomplete-menu {
  box-shadow: 0px 4px 24px 0px #ababab5e !important;
  border-radius: 4px !important;
  background-color: white !important;
  padding-top: 0px;
  margin-top: 8px;
}

.deal-settings-dropdown-skeleton-container {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #d0d2d8;
  border-radius: 8px;
  height: 50px;
  padding: 12px;
}

.MuiTypography-body1.deal-settings-title-text {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
}

.my-stage {
  padding: 0px;
}

.deal-count-and-sort-section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-width: 450px;
  margin-top: 20px;
  margin-bottom: 16px;
  margin-right: 61px;
}

.sort-menu-container {
  top: 45px;
  width: 194px;
}

.MuiButton-text.sort-menu-button,
.MuiButton-text.sort-menu-top-button,
.MuiButton-text.sort-menu-bottom-button,
.MuiButton-text.option-menu-button,
.MuiButton-text.option-menu-top-button,
.MuiButton-text.option-menu-bottom-button {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 16px 12px 16px;
}

.MuiButton-text.sort-menu-top-button,
.MuiButton-text.option-menu-top-button {
  border-radius: 12px 12px 0px 0px;
}

.MuiButton-text.sort-menu-bottom-button,
.MuiButton-text.option-menu-bottom-button {
  border-radius: 0px 0px 12px 12px;
}

.MuiButton-text.sort-menu-button:hover {
  cursor: pointer;
  background-color: #f0f0f3;
}

.MuiButton-text.sort-menu-top-button:hover {
  cursor: pointer;
  background-color: #f0f0f3;
  border-radius: 12px 12px 0px 0px;
}

.MuiButton-text.sort-menu-bottom-button:hover {
  cursor: pointer;
  background-color: #f0f0f3;
  border-radius: 0px 0px 12px 12px;
}

.MuiTypography-body1.sort-menu-text,
.MuiTypography-body1.option-menu-text {
  color: #0b0b0c;
}

.MuiTypography-body1.selected-sort-menu-text {
  color: #ff6a00;
}

.MuiTypography-body1.deal-count {
  font-size: 16px;
  font-weight: 700;
}

.MuiButton-text.sort-deals-button {
  min-width: fit-content;
}

.MuiButton-text.sort-deals-button:hover {
  background-color: transparent;
}

.deal-list-column {
  width: 100%;
  min-width: 450px;
  /* max-width: 503px; */
  margin-right: 61px;
  padding-top: 0px;
}

.deal-list-card {
  border-radius: 8px !important;
  margin-bottom: 16px;
  /* margin-top: 5%; */
  /* box-shadow: 0px 3px 8px grey !important; */
  background-color: white !important;
}

.deal-list-card:hover {
  /* box-shadow: 0px 3px 8px #827ffc !important; */
  cursor: pointer;
}

.deal-list-text > .MuiListItemText-primary {
  height: 100%;
}

.deal-card-container {
  /* position: static; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deal-list-inside-box-selected {
  border: 1px solid #ff6a00;
  border-radius: 8px !important;
  width: 100%;
  height: fit-content;
}

.deal-list-inside-box {
  border: 1px solid #d0d2d8;
  border-radius: 8px !important;
  width: 100%;
  height: fit-content;
}

/* .deal-list-inside-box:hover {
  background-color: #827ffc;
  opacity: 0.9;
  border: 3px solid #827ffc;
} */

.MuiListItem-padding.deal-list-text-overlay {
  height: 100%;
  padding: 12px 16px 12px 16px;
}

.deal-list-text {
  height: 100%;
  margin-left: 7px;
  /* margin-top: 5%; */
}

.deal-card-name-stage-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.MuiTypography-body1.deal-card-full-name-text {
  font-size: 18px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.deal-card-name-stage-section-right {
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: fit-content;
}

.MuiTypography-body1.deal-card-stage-text {
  font-size: 10px;
  font-weight: 700;
  height: 24px;
  color: #ff6a00;
  /* background-color: #FAC8B2; */
  display: flex;
  width: fit-content;
  align-items: center;
  /* border-radius: 1000px; */
  padding: 4px 8px 4px 8px;
}

.deal-card-stage-skeleton-wrapper {
  border-radius: 1000px;
  overflow: hidden;
  border: none;
  height: fit-content;
}

.MuiButton-text.ellipsis-button,
.MuiButton-text.ellipsis-button-no-background {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 2000px;
  margin-left: 10px;
}

.MuiButton-text.ellipsis-button {
  background-color: #d0d2d8;
}

.MuiButton-text.ellipsis-button-no-background {
  background-color: transparent;
}

.MuiButton-text.ellipsis-button:hover {
  background-color: #d0d2d8;
  /* box-shadow: 0px 3px 8px #827ffc !important; */
  cursor: pointer;
}

.MuiButton-text.ellipsis-button-no-background:hover {
  background-color: transparent;
  cursor: pointer;
}

.option-menu-container {
  top: 40px;
  width: 228px;
}

.MuiButton-text.option-menu-button,
.MuiButton-text.option-menu-top-button {
  border-bottom: 0.5px solid #11111140;
}

.MuiButton-text.option-menu-button {
  border-radius: 0 !important;
}

.MuiTypography-body1.option-menu-delete-text {
  /* color: #fe7283; */
  color: #e35050;
}

.MuiButton-text.option-menu-button:hover {
  cursor: pointer;
  background-color: white;
}

.MuiButton-text.option-menu-top-button:hover {
  cursor: pointer;
  background-color: white;
  border-radius: 12px 12px 0px 0px;
}

.MuiButton-text.option-menu-bottom-button:hover {
  cursor: pointer;
  background-color: white;
  border-radius: 0px 0px 12px 12px;
}

.MuiTypography-body1.deal-card-attribute-text {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.deal-card-owner-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.deal-card-owner-section-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.deal-card-owner-profile-picture {
  height: 24px !important;
  width: 24px !important;
  margin-right: 8px;
}

.MuiTypography-body1.deal-card-owner-text {
  color: #ff6a00;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.MuiTypography-body1.deal-card-updated-at-text {
  color: #868686;
  font-size: 12px;
  font-weight: 600;
}

/* Selected Deal: */
.deal-list-overlay-right {
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.select-to-see-preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preview-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 2000px;
  background-color: #ff6a0026;
  margin-bottom: 25px;
}

.MuiTypography-body1.preview-text {
  font-size: 18px;
  font-weight: 600;
  color: #696975;
}

.deal-selected-item {
  height: 100%;
}

.deal-selected-card {
  height: 100%;
  width: 100%;
  /* max-height: 200px;
  margin-top: 120px;
  margin-left: 40px;
  display: inline-block;
  float: right;
  position: sticky;
  position: -webkit-sticky; Safari
  top: 15px; */
}

.MuiTypography-body1.selected-deal-about-title-text {
  font-size: 14px;
  font-weight: 700;
  margin-top: 44px;
  margin-left: 10%;
}

.MuiTypography-body1.selected-deal-about-id-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-left: 10%;
  color: #ff6a00;
}

.selected-deal-section-title-container {
  display: flex;
  justify-content: space-between;
  max-width: 740px;
  min-width: 450px;
  margin-top: 16px;
  margin-left: 10%;
  margin-right: 15%;
}

.MuiTypography-body1.selected-deal-section-title-text {
  font-size: 14px;
  font-weight: 700;
  /* margin-top: 16px;
  margin-left: 10%; */
}

.selected-deal-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d2d8;
  border-radius: 8px;
  padding: 16px;
  max-width: 740px;
  min-width: 450px;
  /* margin-top: 16px; */
  margin-top: 46px;
  margin-left: 10%;
  margin-right: 15%;
}

.deal-selected-card-item {
  width: 100%;
  height: 100%;
}

.selected-deal-name-stage-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f3;
  padding-bottom: 16px;
}

.MuiTypography-body1.selected-deal-full-name-text {
  font-size: 28px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.selected-deal-name-stage-section-right {
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: fit-content;
}

.MuiTypography-body1.selected-deal-stage-text {
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  color: #ff6a00;
  /* background-color: #FAC8B2; */
  display: flex;
  /* width: fit-content; */
  align-items: center;
  border-radius: 1000px;
  padding: 4px 8px 4px 8px;
}

.MuiTypography-body1.selected-deal-section-header-text {
  font-size: 11px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 15px;
  letter-spacing: 0.15em;
  text-align: left;
}

.selected-deal-attribute-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.MuiTypography-body1.selected-deal-attribute-label-text,
.MuiTypography-body1.selected-deal-attribute-val-text {
  font-size: 14px;
  font-weight: 400;
}

.selected-deal-owner-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected-deal-owner-profile-picture {
  height: 24px !important;
  width: 24px !important;
  margin-right: 8px;
}

.MuiTypography-body1.selected-deal-owner-text {
  color: #ff6a00;
  font-size: 11px;
  font-weight: 600;
}

.MuiButton-text.selected-deal-add-button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 1000px;
}

.MuiButton-text.selected-deal-add-button:hover {
  cursor: pointer;
  background-color: transparent;
  height: 24px;
  width: 24px;
}

/* .free-notes-text-area:focus {
  border: 3px solid #868686 !important;
} */

.selected-deal-appointment-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d2d8;
  border-radius: 8px;
  max-width: 740px;
  min-width: 450px;
  padding: 12px;
  margin-top: 16px;
  margin-left: 10%;
  margin-right: 15%;
}

.selected-deal-no-appointments-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d2d8;
  border-radius: 8px;
  max-width: 740px;
  min-width: 450px;
  padding: 16px;
  margin-top: 16px;
  margin-left: 10%;
  margin-right: 15%;
}

.selected-deal-notes-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d2d8;
  border-radius: 8px;
  padding: 16px;
  max-width: 740px;
  min-width: 450px;
  margin-top: 16px;
  margin-left: 10%;
  margin-right: 15%;
}

.selected-deal-appointment-top-section,
.selected-deal-comment-top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-deal-appointment-top-section {
  margin-bottom: 4px;
}

.selected-deal-appointment-top-left-container {
  display: flex;
  align-items: center;
}

.MuiTypography-body1.selected-deal-appointment-date-text {
  color: #222428;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-left: 12px;
}

.MuiTypography-body1.selected-deal-appointment-details-text {
  color: #060606;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-left: 36px;
}

.selected-deal-appointment-top-right-container {
  display: flex;
  align-items: center;
  position: relative;
}

.selected-deal-images-section {
  display: flex;
  max-width: 740px;
  min-width: 450px;
  margin-top: 16px;
  margin-left: 10%;
  margin-right: 15%;
}

.selected-deal-images-section {
  justify-content: flex-start;
}

.selected-deal-no-images-section {
  display: flex;
  max-width: 740px;
  min-width: 450px;
  margin-top: 26px;
  margin-bottom: 26px;
  margin-left: 10%;
  margin-right: 15%;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid #d0d2d8;
  border-radius: 8px;
}

.selected-deal-no-images-loading-section {
  display: flex;
  max-width: 740px;
  min-width: 450px;
  margin-top: 16px;
  margin-left: 10%;
  margin-right: 15%;
  justify-content: center;
  padding: 16px;
}

.MuiButton-text.image-modal-button {
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  padding: 0px;
}

.MuiButton-text.delete-image-modal-button {
  background-color: #060606;
  display: flex;
  position: absolute;
  z-index: 1;
  left: 60px;
  bottom: 60px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  min-width: 16px;
  padding: 0;
  border-radius: 2000px;
}

.MuiButton-text.delete-image-modal-button:hover {
  background-color: #060606;
}

.selected-deal-comment-top-section {
  margin-bottom: 8px;
}

.selected-deal-comment-owner-container {
  display: flex;
  align-items: center;
}

.selected-deal-comment-owner-profile-picture {
  height: 32px !important;
  width: 32px !important;
  margin-right: 8px;
}

.MuiTypography-body1.selected-deal-comment-owner-text {
  color: #222428;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
}

.MuiTypography-body1.selected-deal-comment-created-at-text {
  color: #868686;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: right;
}

.selected-deal-comment-created-at-container {
  display: flex;
}

.MuiTypography-body1.selected-deal-comment-content-text {
  color: #060606;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.MuiTypography-body1.no-appointments-text,
.MuiTypography-body1.no-notes-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiButton-text.selected-deal-meta-data-button,
.MuiButton-text.selected-deal-meta-data-button:hover {
  background-color: #ff6a00;
  padding: 12px;
}

.MuiTypography-body1.selected-deal-meta-data-button-text {
  color: white;
  font-weight: 700;
}

.selected-deal-meta-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 450px;
  max-width: 740px;
  margin-top: 16px;
  margin-left: 10%;
  margin-right: 15%;
}

.selected-deal-meta-data-content-container {
  width: 100%;
}

.selected-deal-meta-data-attribute-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MuiTypography-body1.selected-deal-meta-data-sub-label {
  margin-left: 16px;
}

.selected-deal-bottom-section {
  height: 32px;
}

/* Pagination: */
.pagination {
  /* display: inline-block; */
  /* float: right; */
  /* position: -webkit-sticky; Safari */
  /* position: sticky; */
  min-width: 450px;
  margin-top: 15px;
  margin-bottom: 32px;
  margin-right: 61px;
  justify-content: center;
}

.MuiButton-text.pagination-inactive {
  /* border: 1.5px #d0d2d8 solid; */
  border: 1.5px #868686 solid;
}

.MuiButton-text.pagination-active {
  border: 1.5px #ff6a00 solid;
}

.MuiButton-text.pagination-inactive:hover,
.MuiButton-text.pagination-active:hover {
  background-color: transparent;
}

.MuiButton-text.pagination-inactive,
.MuiButton-text.pagination-active {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 2px;
  text-decoration: none;
  margin-left: 4px;
  margin-right: 4px;
}

.MuiButton-text.pagination-inactive,
.MuiButton-text.pagination-active,
.MuiButton-text.pagination-previous-page-button,
.MuiButton-text.pagination-next-page-button {
  max-height: 24px;
  min-height: 24px;
  max-width: 24px;
  min-width: 24px;
}

.MuiButton-text.pagination-previous-page-button,
.MuiButton-text.pagination-next-page-button,
.MuiButton-text.pagination-previous-page-button:hover,
.MuiButton-text.pagination-next-page-button:hover {
  color: #ff6a00;
  background-color: transparent;
}

/* .pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
} */

.MuiTypography-body1.pagination-inactive-text {
  font-size: 12px;
  font-weight: 700;
  /* color: #d0d2d8; */
  color: #868686;
}

.MuiTypography-body1.pagination-active-text {
  font-size: 12px;
  font-weight: 700;
  color: #ff6a00;
}

/* Delete Deal Modal */
.deal-delete-confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  width: 624px;
  height: 236px;
  padding: 40px 40px 32px 40px;
  border-radius: 8px;
}

.MuiTypography-body1.deal-delete-confirmation-top-text {
  color: #222428;
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
}

.MuiTypography-body1.deal-delete-confirmation-bottom-text {
  color: #868686;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.deal-delete-confirmation-bottom-container {
  display: flex;
  justify-content: space-evenly;
}

.MuiButton-text.cancel-delete-deal-button,
.MuiButton-text.cancel-delete-deal-button:hover {
  background-color: #868686;
}

.MuiButton-text.confirm-delete-deal-button,
.MuiButton-text.confirm-delete-deal-button:hover {
  /* background-color: #ff5c77; */
  background-color: #e35050;
}

.MuiButton-text.cancel-delete-deal-button,
.MuiButton-text.cancel-delete-deal-button:hover,
.MuiButton-text.confirm-delete-deal-button,
.MuiButton-text.confirm-delete-deal-button:hover {
  width: 124px;
  padding: 12px;
  border-radius: 8px;
}

.MuiTypography-body1.deal-delete-button-text {
  color: white;
  font-weight: 700;
}
