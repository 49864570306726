#main-section {
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  height: 50vh;
  width: 100%;
  border-radius: 15px;
  background-color: lightgray;
  border: 1px solid purple;
}

#drag_drop_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  border-radius: 15px;
  height: 10vh;
  width: 100%;
}

.drag-active {
  box-shadow: 2px 2px -5px rgb(255, 0, 0);
  /* background-color: white; */
  animation: fadeInOut 1.5s infinite;
}
.non-active-drag {
  background-color: white;
  /* box-shadow: 2px 2px 5px rgb(25, 0, 255); */
}

@keyframes fadeInOut {
  0% {
    background-color: white;
  }
  50% {
    background-color: rgb(230, 230, 230);
  }
  100% {
    background-color: white;
  }
}
