/* Custom styles for SelectedDealDisplay when used in DealDetailsDrawer */

/* Main wrapper for scrollable content */
.drawer-selected-deal-scroll {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Scrollbar styling for better user experience */
.drawer-selected-deal-scroll::-webkit-scrollbar {
  width: 8px;
}

.drawer-selected-deal-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.drawer-selected-deal-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.drawer-selected-deal-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Container for the SelectedDealDisplay component */
.drawer-selected-deal {
  width: 100%;
  height: 100%;
}
