#tabs-box {
  display: flex;
}
.config-tab {
  position: relative;
  top: 1px;
  padding: 5px 15px;
  font-weight: bold;
  font-size: 1.6rem;
  min-width: 150px;
  /* background-color: red; */
  border: 1px solid black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.config-tab:hover {
  cursor: pointer;
}
.active {
  background-color: white;
  color: #ff6a00;
  border-bottom: none;
}
.non-active {
  background-color: rgb(181, 181, 181);
  color: rgb(47, 47, 47);
}
.non-active:hover {
  background-color: rgb(93, 93, 93);
  color: white;
}
#back-to-list-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(111, 111, 111);
  border-radius: 10px;
  padding: 15px 10px;
  /* background-color: #fff1ff; */
  width: 140px;
  height: 20px;
}
#back-to-list-button:hover {
  background-color: rgb(235, 217, 239);
  cursor: pointer;
}
