.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 0;
  border-radius: 5px;
  padding: 5px;
}
.kpi-menu-item {
  border: 1px solid gray;
}

.checked-kpis-container {
  width: 100%;
  height: 8ch;
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 8px;
  padding: 5px 10px;
}

.kpi-title-box {
  background-color: #fac8b2;
  color: #ff6a00;
  border-radius: 5px;
  padding: 5px;
  margin: 2px;
  transition: 0.3s all ease-in-out;
}
.kpi-title-box:hover {
  background-color: rgb(232, 231, 231);
}
.by-hour-container {
  padding: 5px 5px 5px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4ch;
  width: 20ch;
  border: 1px solid white;
}
.testing1 {
  color: rgb(231, 231, 231);
}
.clear-select-all-container {
  width: 100%;
  height: 4ch;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 15px 2px 2px;
}
.clear-select-all {
  padding: 2px 10px;
  border: 1px solid #ff6a00;
  border-radius: 3px;
  color: #ff6a00;
  background-color: #fac8b2;
}
.list-container {
  max-height: 75%;
  overflow-y: auto;
  margin-top: 5px;
  padding-right: 5px;
}
.list-container::-webkit-scrollbar {
  width: 10px;
}
.list-container::-webkit-scrollbar-thumb {
  background: #fac8b2;
  border-radius: 8px;
}

.list-container::-webkit-scrollbar-thumb:hover {
  background: #ff6a00;
}
.selection-modal-container {
  background-color: white;
  height: 70vh;
  width: 40vw;
  /* overflow-y: auto; */
  /* padding: 10px; */
  border-radius: 15px;
}
.clear-search-icon {
  font-size: 1.2rem;
  color: #ff6a00;
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 20px;
}
.reports-container {
  display: grid;
  padding: 35px 15px;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.report-generator-box {
  position: relative;
  background-color: white;
  padding: 15px 40px;
  box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-out;
}
.selection-box {
  width: 40rem;
  height: 5rem;
  padding: 8px;
  margin-bottom: 5px;
  /* border: '1px solid #D0D2D8'; */
  border: 1px solid #d0d2d8;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  scrollbar-width: none;
}

.selected-item {
  white-space: nowrap;
  margin: 2px;
  background-color: #fac8b2;
  padding: 2px 4px;
  height: 1.8rem;
}

.generate-button {
  height: 3.5rem;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 25px;
  border-radius: 8px;
  border: 1px solid #d0d2d8;
  font-weight: bold;
}
.export_full_report_button {
  position: fixed;
  background-color: #ff6a00;
  z-index: 5;
  color: white;
  transition: all 0.3s ease-in-out;
}

.download-report-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 10px;
  color: black;
  transition: 0.2 all ease-in-out;
}
.download-report-button:hover {
  color: #906fd1;
}

.show-report-config-button {
  position: absolute;
  bottom: -25px;
  right: 40px;
  padding: 0 25px;
  width: 5rem;
  background-color: #906fd1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.show-report-config-button:hover {
  background-color: #ff6a00;
}
.single-chart-container {
  position: relative;
  padding: 10px;
  height: 50vh;
  max-width: 100%;
  border-radius: 8px;
  background-color: white;
}
.selection-modal-header {
  width: 100%;
  height: 3rem;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid #d0d2d8;
}

.done-button {
  width: fit-content;
  margin-top: 10px;
}

.done-button-container {
  display: flex;
  justify-content: flex-end;
}
