/* Team Screen */
.MuiButton-text.team-screen-to-team-page-button,
.MuiButton-text.team-screen-to-team-page-button:hover {
  background-color: transparent;
}

/* This prevents background color change for autofill in Chrome and older versions of Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
}

/* Delete Teams Modal */
.delete-teams-confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  width: 624px;
  height: 286px;
  padding: 40px 40px 32px 40px;
  border-radius: 8px;
}

.MuiTypography-body1.delete-teams-confirmation-top-text {
  color: #222428;
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}

.MuiTypography-body1.delete-teams-confirmation-bottom-text {
  color: #868686;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.delete-teams-confirmation-bottom-container {
  display: flex;
  justify-content: space-between;
}

.MuiButton-text.cancel-delete-teams-button,
.MuiButton-text.cancel-delete-teams-button:hover {
  background-color: white;
  border: 1px solid #cfcfcf;
}

.MuiButton-text.confirm-delete-teams-button,
.MuiButton-text.confirm-delete-teams-button:hover {
  /* background-color: #ff5c77; */
  background-color: #e35050;
}

.MuiButton-text.cancel-delete-teams-button,
.MuiButton-text.cancel-delete-teams-button:hover,
.MuiButton-text.confirm-delete-teams-button,
.MuiButton-text.confirm-delete-teams-button:hover {
  width: 262px;
  padding: 12px;
  border-radius: 8px;
}

.MuiTypography-body1.cancel-delete-teams-button-text {
  color: #323232;
  font-weight: 700;
}

.MuiTypography-body1.delete-teams-button-text {
  color: white;
  font-weight: 700;
}

/* Team Page */
.MuiButton-text.team-page-back-button,
.MuiButton-text.team-page-back-button:hover {
  background-color: transparent;
}

.team-page-section-button {
  color: #ff6a00;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-right: 25px;
  padding-bottom: 10px;
}

#about-card {
  padding: 15px;
  width: 100%;
  min-width: fit-content;
  background-color: #f0f0f3;
  border-radius: 8px;
}

.MuiButton-text.show-tree-button,
.MuiButton-text.show-tree-button:hover {
  background-color: transparent;
}

.MuiButton-text.linked-about-item-team-button,
.MuiButton-text.linked-about-item-team-button:hover {
  background-color: transparent;
}

#team-members-list {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}

/* .team-member-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f3;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 5px;
} */

.team-member-list-item-left-container,
.linked-team-list-item-left-container {
  display: flex;
}

.team-member-list-item-left-text-container,
.linked-team-list-item-left-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .team-member-list-item-right-container,
.linked-team-list-item-right-container {
  position: relative;
  height: 45px;
  display: flex;
  align-items: flex-start;
} */

.MuiButton-text.team-member-option-button,
.MuiButton-text.linked-team-option-button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 2000px;
  margin-left: 10px;
}

.MuiButton-text.team-member-option-button,
.MuiButton-text.team-member-option-button:hover,
.MuiButton-text.linked-team-option-button,
.MuiButton-text.linked-team-option-button:hover {
  background-color: transparent;
}
