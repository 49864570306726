.circle-level-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 2px 0;
}
.circle-level-list-item:hover {
  background-color: rgba(237, 237, 237, 0.335);
}
.circle-level-number-box {
  background-color: #ff6a00;
  min-width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 3ch;
}
.add-level-button {
  padding: 5px;
  border-radius: 8px;
  background-color: #ff6a00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-change-button {
  background-color: #ff6a00;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: '50%';
  border: 1px solid white;
}
.circle-level-form {
  min-height: 15px;
  flex-grow: 1;
}
.borderless-input {
  border: none;
  outline: none;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1ch;
}
.borderless-input:focus {
  border-bottom: 2px solid gray;
}
.description-teamlevel-input {
  width: 100%;
  padding: 5px;
  font-weight: bold;
  font-size: 1.1rem;
  resize: none;
  border-radius: 5px;
  border: none;
}
.level-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  min-width: 30ch;
}
.number-display-box {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 5rem;
  font-weight: bold;
  width: 7rem;
  height: 7rem;
  border-radius: 8px;
}
.teamLevel-buttons-box {
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
}
#save-teamLevel-button {
  background-color: #ff6a00;
  transition: all 0.2s ease-in-out;
}
#save-teamLevel-button:hover {
  background-color: #d8ceed;
  color: #ff6a00;
}
#delete-teamLevel-button {
  background-color: #ff6a00;
  transition: all 0.2s ease-in-out;
}
#delete-teamLevel-button:hover {
  background-color: #d8ceed;
  color: #cd2727;
}
.teamLevel-form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 5px;
  min-width: 15ch;
  color: white;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}
#delete-circle-level-modal {
  padding: 25px 35px;
  border-radius: 18px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#delete-modal-buttons-box {
  display: flex;
  margin-top: 4ch;

  width: 100%;
  justify-content: space-evenly;
}
