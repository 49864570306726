.option-menu-container {
  position: absolute;
  right: 0px;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 228px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 24px 0px #ababab5e;
}

.option-menu-container {
  z-index: 10001;
}

.option-menu-container-background {
  position: fixed;
  /* top: 0px; */
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}

.option-menu-change-role-permissions-container,
.option-menu-delete-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.MuiButton-text.option-menu-button,
.MuiButton-text.option-menu-top-button,
.MuiButton-text.option-menu-bottom-button {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 16px 12px 16px;
}

.MuiButton-text.option-menu-top-button {
  border-radius: 12px 12px 0px 0px;
}

.MuiButton-text.option-menu-bottom-button {
  border-radius: 0px 0px 12px 12px;
}

.MuiButton-text.option-menu-button,
.MuiButton-text.option-menu-top-button {
  border-bottom: 0.5px solid #11111140;
}

.MuiButton-text.option-menu-button {
  border-radius: 0 !important;
}

.MuiTypography-body1.option-menu-delete-text {
  /* color: #fe7283; */
  color: #e35050;
}

.MuiButton-text.option-menu-button:hover {
  cursor: pointer;
  background-color: white;
}

.MuiButton-text.option-menu-top-button:hover {
  cursor: pointer;
  background-color: white;
  border-radius: 12px 12px 0px 0px;
}

.MuiButton-text.option-menu-bottom-button:hover {
  cursor: pointer;
  background-color: white;
  border-radius: 0px 0px 12px 12px;
}

/* Remove Member Modal */
.remove-member-confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  width: 624px;
  height: 286px;
  padding: 40px 40px 32px 40px;
  border-radius: 8px;
}

.MuiTypography-body1.remove-member-confirmation-top-text {
  color: #222428;
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}

.MuiTypography-body1.remove-member-confirmation-bottom-text {
  color: #868686;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.remove-member-confirmation-bottom-container {
  display: flex;
  justify-content: space-between;
}

.MuiButton-text.cancel-remove-member-button,
.MuiButton-text.cancel-remove-member-button:hover {
  background-color: white;
  border: 1px solid #cfcfcf;
}

.MuiButton-text.confirm-remove-member-button,
.MuiButton-text.confirm-remove-member-button:hover {
  /* background-color: #ff5c77; */
  background-color: #e35050;
}

.MuiButton-text.cancel-remove-member-button,
.MuiButton-text.cancel-remove-member-button:hover,
.MuiButton-text.confirm-remove-member-button,
.MuiButton-text.confirm-remove-member-button:hover {
  width: 262px;
  padding: 12px;
  border-radius: 8px;
}

.MuiTypography-body1.cancel-remove-member-button-text {
  color: #323232;
  font-weight: 700;
}

.MuiTypography-body1.remove-member-button-text {
  color: white;
  font-weight: 700;
}
