@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

a {
  color: var(--application-color-foreground);
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
