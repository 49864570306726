.table-header {
  background-color: var(--application-color-sub-backgound);
  background-image: url('/public/images/rings.svg');
  background-repeat: no-repeat;
  background-position: top right;
}

.reward {
  background-color: var(--application-color-card);
  border-radius: 20px;
}

.reward-image {
  width: 100%;
  height: 100%;
}

.scorecard-box {
  justify-content: center;
}

.scorecard-dropdown-overlay {
  margin-bottom: 40px;
}

.scorecard-dropdown {
  background-color: #827ffc !important;
}

.scorecard-dropdown-box {
  background-color: black !important;
  border-radius: 30px !important;
  color: white !important;
  stroke: white !important;
}

.scorecard-card-overlay {
  border-radius: 10% !important;
  box-shadow: 2px 3px 8px grey !important;
  background-color: black !important;
}

.scorecard-card-overlay:hover {
  box-shadow: 2px 3px 8px #827ffc !important;
}

.scorecard-card {
  border: 1px solid grey;
  border-radius: 10%;
  width: 200px;
  height: 160px;
}

.scorecard-card:hover {
  background-color: #827ffc;
  opacity: 0.9;
  border: 3px solid #827ffc;
}

.scorecard-kpi-value {
  color: white;
  text-align: center;
  margin-top: 7%;
  margin-bottom: 2%;
  padding-top: 7%;
}

.scorecard-kpi-title {
  color: white;
  text-align: center;
}
