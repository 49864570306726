#training-home-container {
  display: flex;
  flex-direction: column;
  padding: 80px 5vw;
}
#training-home-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
#page-title-box {
  display: flex;
  /* align-items: center; */
  align-items: flex-end;
}
#type-selection-box {
  position: relative;
  bottom: -5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.dot {
  position: absolute;
  bottom: -8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.training-type-option {
  position: relative;
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.category-row-continaer {
  /* border: 1px dotted red; */
  margin-top: 25px;
}
.category-row-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.category-row-lessons-container {
  margin: 15px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2vw;
  justify-content: space-between;
}
.category-list-view-container {
  display: 'flex';
  flex-direction: column;
  margin: 15px 0;
  gap: 2vw;
}
.single-lesson-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  height: 30vh;
  box-shadow: 2px 3px 15px lightgray;
  transition: 0.3s all ease-in-out;
}
.single-lesson-card:hover {
  cursor: pointer;
  transform: translateY(-5px) scale(102%);
  box-shadow: 2px 5px 15px gray;
}

@keyframes gradiantAnimation {
  0%,
  100% {
    background-size: 100%;
  }
  50% {
    background-size: 150%;
  }
}

@keyframes contrastAnimation {
  0%,
  100% {
    filter: contrast(50%);
  }
  50% {
    filter: contrast(100%);
  }
}

@keyframes bounceEffect {
  0%,
  100% {
    transform: translateY(0);
    box-shadow: 2px 3px 15px lightgray;
    filter: blur(0px);
  }
  50% {
    transform: translateY(-10px); /* Adjust the bounce height as needed */
    box-shadow: 2px 5px 15px gray;
    filter: blur(1px);
  }
}

.lesson-card-image {
  height: 65%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.single-lesson-list {
  display: flex;
  align-items: center;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  /* border: 1px solid red; */
  box-shadow: 2px 3px 15px lightgray;
  transition: 0.3s all ease-in-out;
}
.single-lesson-list:hover {
  cursor: pointer;
  box-shadow: 2px 5px 15px gray;
  transform: translateY(-1px) scale(101%);
}
.lesson-list-image {
  /* border: 1px solid blue; */
  border-radius: 8px;
  height: 104px;
  width: 184px;
  background-size: cover;
  background-position: center;
}

#single-video-page-view {
  padding-top: 90px;
  background-color: black;
  height: 100vh;
  width: 100vw;
}
#display-controls-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

#video-upload-backdrop {
  background-color: white;
  height: 70vh;
  width: 80vw;
  border-radius: 15px;
}
#video-upload-topbar {
  position: relative;
  background-color: #d0d2d8;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.video-type-button {
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding: 10px;
  min-width: 12vw;
  /* color: white; */
}
.dropzone {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.dropzone-on-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.progress-bar {
  background-color: #d0d2d8;
  position: relative;
  margin: 1rem 0;
  width: 100%;
  height: 2rem;
  border-radius: 5px;
  border: 1px solid gray;
}
.progress-bar-fill {
  border-radius: 5px;
  width: 30vw;
  height: 2rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: inset 0px 0px 5px white;

  /* background-color: rgb(61, 169, 61); */
}
#close-upload-form-button {
  position: absolute;
  height: 2rem;
  width: 2rem;
  background-color: red;
  right: 15px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
#lesson-detail-form {
  display: 'relative';
  border-radius: 15px;
  margin: 5px;
  /* height: 50vh; */
  max-height: 75vh;
  overflow-y: auto;
  min-width: 40vw;
  background-color: #f0f0f3;
}
#lesson-detail-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin: 5px;
  /* height: 100%; */
  min-width: 25vw;
  background-color: #f0f0f3;
}
#lesson-detail-form-top {
  background-color: #d0d2d8;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px;
}

.input-styling {
  padding: 12px;
  border-radius: 8px;
  border: none;
  /* border: 1px solid #222428; */
  background-color: white;
  margin-bottom: 5px;
}

#dropzone-button {
  background-color: #ff6a00;
  border: 1px solid #ff6a00;
  padding: 10px 15px;
  margin: 10px;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  transition: all 0.2s ease-in-out;
}
#dropzone-button:hover {
  cursor: pointer;
  background-color: white;
  color: #ff6a00;
}
.thumbnail-button {
  background-color: #ff6a00;
  padding: 10px;
  border-radius: 5px;
}
.thumbnail-disabled {
  background-color: gray;
  padding: 10px;
  border-radius: 5px;
}
.submit-button-box {
  display: flex;
  justify-content: center;
  background-color: #ff6a00;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: inset 0px 0px 0px #8d73c0;
  transition: all 0.2s ease-in-out;
}
.submit-button-box:hover {
  box-shadow: inset 0px 0px 15px #8d73c0;
}

.card-edit-button {
  position: absolute;
  right: 10px;
  /* top: 50%; */
  top: 5px;
  padding: 8px;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  /* border: 1px solid lightgray; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  z-index: 0;
}
.card-edit-button:hover {
  background-color: gray;
}
.edit-dots {
  z-index: 0;
}
.edit-dots:hover {
  color: white;
}
@media (max-width: 960px) {
  .category-row-lessons-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .category-row-lessons-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Media query for the smallest screens */
@media (max-width: 320px) {
  .category-row-lessons-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
