.permissions-console-container {
  background-color: white;
  margin: 0px;
  right: 10;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  max-height: max-content;
  flex-grow: 1;
  /* width: 100%;
  height: auto;
  max-width: 100%;
  box-sizing: border-box; */
  /* box-sizing: border-box; */
}

.permission-tabs-container {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  padding-left: 25px;
}

.custom-tab {
  font-size: 15px; /* Label size */
  color: gray; /* Default color */
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
}

.custom-tab.Mui-selected {
  color: #ff6a00 !important; /* Color when selected */
}

.custom-tabs .MuiTabs-indicator {
  background-color: #ff6a00; /* Color of the selected tab underline */
  height: 3px;
}

.permission-section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  margin-bottom: 20px;
}
