/* Profile About Page */
.MuiButton-text.profile-about-page-back-button,
.MuiButton-text.profile-about-page-back-button:hover {
  background-color: transparent;
}

.profile-about-page-section-button {
  color: #ff6a00;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-right: 25px;
  padding-bottom: 10px;
}

#about-subpage {
  background-color: #f0f0f3;
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  padding: 24px 16px 24px 16px;
  border-radius: 8px;
}

#team-list {
  display: flex;
  flex-direction: column;
}

.team-list-item-left-container {
  display: flex;
}

.team-list-item-left-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MuiButton-text.team-option-button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 2000px;
  margin-left: 10px;
}

.MuiButton-text.team-option-button,
.MuiButton-text.team-option-button:hover {
  background-color: transparent;
}

/* .teams-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f3;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 5px;
} */

.teams-list-item-left-container {
  display: flex;
}

.teams-list-item-left-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .teams-list-item-right-container {
  position: relative;
  height: 45px;
  display: flex;
  align-items: flex-start;
} */

.MuiButton-text.teams-option-button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 2000px;
  margin-left: 10px;
}

.MuiButton-text.teams-option-button,
.MuiButton-text.teams-option-button:hover {
  background-color: transparent;
}
