.am-lt-modal-container {
  position: relative;
  background-color: white;
  height: fit-content;
  max-height: 70vh;
  width: 772px;
  /* overflow-y: auto; */
  padding: 0px 24px 0px 24px;
  border-radius: 8px;
}

.am-lt-modal-top-container {
  position: fixed;
  z-index: 6;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 93%;
  max-width: 724px;
  padding-top: 24px;
}

.am-lt-modal-top-middle-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 440px;
}

.am-lt-modal-top-right-container {
  width: 110px; /* match width of top-left container to keep middle centered */
}

.am-lt-modal-middle-container {
  margin-top: 164px;
  overflow-y: auto;
  /* height: calc(70vh - 180px); */
  max-height: calc(70vh - 264px);
  margin-bottom: 100px;
}

.am-lt-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f0f0f3; */
  border: 1px solid #e2e2e2;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 5px;
}

.am-lt-list-item-left-container {
  display: flex;
}

.am-lt-list-item-left-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.am-lt-list-item-right-container {
  height: 45px;
  display: flex;
  align-items: flex-start;
}

/* .am-lt-modal-bottom-container {
  display: flex;
  justify-content: flex-end;
} */

.am-lt-modal-bottom-container {
  position: absolute;
  bottom: 0;
  z-index: 6;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  width: 93%;
  max-width: 724px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #e2e2e2;
}

.MuiButton-text.am-lt-modal-submit-button,
.MuiButton-text.am-lt-modal-submit-button:hover {
  background-color: #ff6a00;
  margin-top: 16px;
}

.MuiButton-text.top-am-lt-modal-submit-button,
.MuiButton-text.top-am-lt-modal-submit-button:hover {
  background-color: #ff6a00;
}
