.row-position-overlay {
}

.row-profile-image {
}

.date-picker-overlay {
  margin: 10px;
}

.react-datepicker {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: lightgray;
  display: inline-block;
  position: relative;
  margin-top: 1px !important;
  border: 1px solid transparent !important;
  border-radius: 5px !important;
  margin-left: 6px !important;
}

.date-picker-text {
}

.refresh-charts-button {
  margin-top: 20px !important;
  margin-bottom: 70px !important;
  margin-left: 174px !important;
  border: 1px solid var(--component-background) !important;
  border-radius: 20px !important;
  background-color: var(--table-row-background) !important;
  color: white !important;
  width: 200px;
  font-size: large !important;
}

.refresh-charts-button:hover {
  background-color: #827ffc !important;
  border: 3px solid #827ffc !important;
}

.settings-and-chart-box {
  width: 1138px;
}

.chart-settings-overlay {
  width: fit-content;
  padding-left: 300px;
}

.button-group-overlay {
  border-radius: 30px;
  background-color: var(--component-background);
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 3px;
}

.button-group {
  border-radius: 30px !important;
  padding-right: 10px;
  padding-left: 10px;
}

.button-group-date {
  margin-left: 15%;
}

.button-in-group-menu {
  border: 1px solid grey !important;
  border-radius: 10px !important;
  margin: 10px !important;
}

.button-in-group-date {
  border: 1px solid grey !important;
  border-radius: 10px !important;
  margin: 10px !important;
}

.button-in-group:hover {
  background-color: #827ffc !important;
  border: 2px solid #827ffc !important;
}

.button-in-group-menu:focus {
}

.button-in-group-date:focus {
}

.page-title-overlay {
}

.chart-page-title {
  border: 1px solid var(--component-background);
  border-radius: 30px;
  padding: 10px;
  margin-bottom: 30px;
  width: 300px;
  margin-inline: 35.5% !important;
}

.chart-overlay {
  justify-content: center;
}

.chart-grid-overlay {
  background-color: var(--component-background);
  border-radius: 30px;
  align-items: center;
  padding-left: 35px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.chart-grid {
  border: 1px solid grey;
  border-radius: 30px;
  margin: 12px !important;
  background-color: black;
  box-shadow: 0px 3px 8px grey;
  padding-left: 23px !important;
}

.chart-grid:hover {
  border: 2px solid #827ffc;
  box-shadow: 3px 4px 8px #827ffc;
}

.victory-bar:hover {
}

.chart-title {
  margin: 10px 10px 0px 0px !important;
  font-size: 125% !important;
}

.chart-title:only-child {
}
