.MuiButton-text.cancel-unlink-team-button,
.MuiButton-text.cancel-unlink-team-button:hover {
  border: 1px solid #cfcfcf;
  background-color: white;
}

.MuiButton-text.confirm-unlink-team-button,
.MuiButton-text.confirm-unlink-team-button:hover {
  /* background-color: #ff5c77; */
  background-color: #e35050;
}

.MuiButton-text.cancel-unlink-team-button,
.MuiButton-text.cancel-unlink-team-button:hover,
.MuiButton-text.confirm-unlink-team-button,
.MuiButton-text.confirm-unlink-team-button:hover {
  width: 262px;
  padding: 12px;
  border-radius: 8px;
}

.MuiButton-text.option-menu-only-button {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 16px 12px 16px;
  border-radius: 12px;
}

.MuiButton-text.option-menu-only-button:hover {
  background-color: white;
}
