.campaign-title-overlay {
  border-radius: 30px;
  padding: 10px;
  width: fit-content !important;
}

.new-campaign-title-overlay {
  border-radius: 30px;
  padding: 10px;
  width: fit-content !important;
  align-self: center;
  margin-top: 20px !important;
}

.title-text {
}

.form-overlay {
  padding-left: 0px !important;
}

.formik-box {
  padding-left: 2%;
}

.copy-button-overlay {
  text-align: center !important;
}

.copy-button {
  background-color: #827ffc !important;
  border-radius: 30px !important;
  color: white !important;
  padding: 5px 15px 5px 15px !important;
  margin-top: 2px !important;
  margin-bottom: 20px !important;
  border: 1px solid #827ffc !important;
  font-size: large !important;
}

.copy-button:hover {
  border: 2px solid #827ffc !important;
}

.form-item-title {
  margin-right: 20px !important;
  padding-top: 8px !important;
  padding-left: 3px !important;
}

.date-picker-description {
  margin-right: 4px !important;
  padding-top: 5% !important;
  padding-left: 3px !important;
}

.dropdown-overlay {
}

.dropdown-selector {
  background-color: white !important;
  border-radius: 30px !important;
}

.dropdown-item {
  border-radius: 30px !important;
}

.prize-dropdown {
  margin-bottom: 14px !important;
}

.copy-campaign-button {
  margin-left: 34.5% !important;
}

.copy-campaign-button-dropdown {
  margin-left: 28% !important;
}

.paper-overlay {
  padding-top: 4%;
  padding-bottom: 4%;
  background-color: white !important;
  border-radius: 30px !important;
  margin-bottom: 20px;
  width: 650px !important;
}

.advanced-paper-overlay {
  background-color: lightgray !important;
  border-radius: 30px !important;
  width: 650px !important;
  border-radius: 30px !important;
  margin-bottom: 20px;
}

.title-overlay {
  color: black !important;
  margin-bottom: 15px !important;
}

.place-overlay {
  text-align: center !important;
}

.field-overlay {
  margin-bottom: 15px !important;
}

.campaign-field-bubble {
  height: 40px;
  width: 100% !important;
  border-radius: 20px !important;
  border: 1px solid #c1c1c1;
  border-bottom: 3px solid #c1c1c1;
  padding-left: 15px;
}

.campaign-styled-bubble {
  border-radius: 30px !important;
}

fieldset {
  border-radius: 30px !important;
}

.error-message {
  color: #ff1f1f;
  padding-left: 1%;
  font-size: small;
}

.date-picker-overlay {
}

.react-datepicker {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: white !important;
  display: inline-block;
  position: relative;
  border: 1px solid transparent !important;
}

.public-campaign-switch {
  background-color: red !important;
  stroke: black !important;
  color: black !important;
}

.save-button-overlay {
  text-align: center !important;
}

.save-button {
  background-color: #827ffc !important;
  border-radius: 30px !important;
  color: white !important;
  padding: 5px 15px 5px 15px !important;
  margin-top: 2px !important;
  border: 1px solid #827ffc !important;
  font-size: large !important;
}

.save-button:hover {
  border: 2px solid #827ffc !important;
}
