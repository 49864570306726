.MuiContainer-root {
  margin: 0;
}

.rewards-page-overlay {
}

@media all and (min-width: 1670px) {
  .rewards-header {
    margin-left: 4%;
    transition-duration: 1s;
  }
}

@media all and (max-width: 1670px) {
  .rewards-header {
    margin-left: 10%;
    transition-duration: 1s;
  }
}

@media all and (max-width: 1570px) {
  .rewards-header {
    margin-left: 15%;
    transition-duration: 1s;
  }
}

.rewards-button-group {
  padding: 0 !important;
  margin-top: 1%;
}

.rewards-button-in-group {
  margin: 15px !important;
  border-right: none !important;
}

.rewards-button-in-group:hover {
  color: #827ffc !important;
  background-color: #1d1d1d !important;
}

.coin-image-overlay {
  margin-top: 20px !important;
  background-color: #191919;
  width: 118%;
  padding-top: 10px;
}

@media all and (min-width: 1600px) {
  .coin-image {
    width: 68px;
    height: 68px;
    margin-left: 46%;
  }
}

@media all and (max-width: 1600px) {
  .coin-image {
    width: 68px;
    height: 68px;
    margin-left: 44%;
    transition-duration: 0.5s;
  }
}

@media all and (min-width: 1370px) and (max-width: 1400px) {
  .coin-image {
    width: 68px;
    height: 68px;
    margin-left: 43%;
    transition-duration: 0.5s;
  }
}

@media all and (max-width: 1000px) {
  .coin-image {
    width: 68px;
    height: 68px;
    margin-left: 41%;
    transition-duration: 0.5s;
  }
}

@media all and (min-width: 1670px) {
  .add-reward-button {
    margin-left: 25%;
    padding: 0px !important;
    transition-duration: 0.5s !important;
  }
}

@media all and (max-width: 1670px) {
  .add-reward-button {
    margin-left: 37% !important;
    transition-duration: 0.5s !important;
  }
}

@media all and (max-width: 1570px) and (min-width: 1370px) {
  .add-reward-button {
    margin-left: 30% !important;
    transition-duration: 0.5s !important;
  }
}

@media all and (max-width: 1370px) {
  .add-reward-button {
    margin-left: 20% !important;
    transition-duration: 0.5s !important;
  }
}

.add-reward-photo {
  max-width: 50px;
  max-height: 50px;
}

@media all and (min-width: 1670px) {
  .reward-list-overlay {
    background-color: #191919;
    width: 118%;
    flex-direction: row !important;
    transition-duration: 0.5s !important;
  }
}

@media all and (max-width: 1670px) and (min-width: 1600px) {
  .reward-list-overlay {
    background-color: #191919;
    width: 115%;
    flex-direction: row !important;
    transition-duration: 0.5s !important;
  }
}

@media all and (max-width: 1600px) and (min-width: 1470px) {
  .reward-list-overlay {
    background-color: #191919;
    width: 113%;
    flex-direction: row !important;
    transition-duration: 0.5s !important;
  }
}

@media all and (max-width: 1470px) {
  .reward-list-overlay {
    background-color: #191919;
    width: 110%;
    flex-direction: row !important;
    transition-duration: 0.5s !important;
  }
}

.rewards-box {
  columns: 2 auto;
  column-width: 570px !important;
  padding-left: 9%;
  padding-top: 20px;
  background-color: #191919;
  border-bottom: 125px solid #191919;
  resize: both;
}

.reward-in-list {
  background-color: var(--application-color-card);
  border-radius: 20px !important;
  max-width: 450px !important;
  margin-top: 3%;
  margin-bottom: 1%;
  flex-basis: 28% !important;
}

.reward-in-list-image {
  width: 100%;
  border-radius: 18px !important;
  padding: 0px !important;
}

.rewards-title-box {
}

.rewards-title-overlay {
  justify-content: center !important;
  align-items: center !important;
}

.rewards-title {
  padding-top: 5% !important;
  padding-bottom: 5px !important;
  justify-content: center !important;
  align-items: center !important;
  overflow-wrap: break-word !important;
}

.rewards-title-text {
  max-width: 180px;
  line-height: 1.2 !important;
  margin-left: 5px !important;
  font-size: x-large !important;
}

.shadow-button {
  height: 40px;
  borderradius: 16px;
  color: white;
}

.clone-button-overlay {
  text-align: center !important;
  margin-left: 20px;
}

.top-button {
  margin-left: 28%;
}

.details-row1-overlay {
  margin-right: 5%;
}

.quantity-overlay {
  margin-left: 20%;
}

.clone-button {
  background-color: #827ffc !important;
  border-radius: 30px !important;
  color: white !important;
  padding: 5px 15px 5px 15px !important;
  margin-top: 2px !important;
  margin-bottom: 20px !important;
  border: 1px solid #827ffc !important;
  font-size: large !important;
}

.clone-button:hover {
  border: 2px solid #827ffc !important;
}

.copy-button-overlay {
  text-align: center !important;
}

.copy-button {
  background-color: #827ffc !important;
  border-radius: 30px !important;
  color: white !important;
  padding: 5px 15px 5px 15px !important;
  margin-top: 2px !important;
  margin-bottom: 20px !important;
  margin-left: 20px !important;
  border: 1px solid #827ffc !important;
  font-size: large !important;
}

.copy-button:hover {
  border: 2px solid #827ffc !important;
}

.gift-card-button-overlay {
  text-align: center !important;
}

.gift-card-button {
  background-color: white !important;
  border-radius: 30px !important;
  color: black !important;
  padding: 5px 15px 5px 15px !important;
  margin-top: 2px !important;
  border: 1px solid lightgray !important;
  border-bottom: 2px solid lightgray !important;
  font-size: large !important;
  width: 260px;
}

.gift-card-button:hover {
  border: 2px solid black !important;
}

.upload-photo-button-overlay {
  text-align: center !important;
}

.upload-photo-button {
  background-color: white !important;
  border-radius: 30px !important;
  color: black !important;
  padding: 5px 15px 5px 15px !important;
  margin-top: 2px !important;
  margin-left: 64px !important;
  border: 1px solid lightgray !important;
  border-bottom: 2px solid lightgray !important;
  font-size: large !important;
  width: 260px;
}

.upload-photo-button:hover {
  border: 2px solid black !important;
}

.reward-name {
  width: 260px !important;
}

.field-bubble {
  height: 40px;
  border-radius: 20px !important;
  border: 1px solid #c1c1c1;
  border-bottom: 3px solid #c1c1c1;
  padding-left: 15px;
}

.field-bubble:hover {
  border-color: black;
}

.reward-description {
  margin-left: 64px !important;
  width: 260px !important;
}

.reward-details-overlay {
  margin-top: 20px !important;
}

.reward-quantity {
  width: 180px !important;
}

.reward-limit {
  width: 180px !important;
  margin-left: 21px !important;
}

.reward-cost {
  width: 180px !important;
  margin-left: 21px !important;
}

.react-datepicker {
}

.formik-box {
}

.formik-overlay {
}

.error-message-name {
  color: #ff1f1f;
  padding-left: 2%;
  font-size: small;
}

.error-message-description {
  color: #ff1f1f;
  padding-left: 21%;
  font-size: small;
}

.error-message-quantity {
  color: #ff1f1f;
  padding-left: 3%;
  font-size: small;
}

.error-message-limit {
  color: #ff1f1f;
  padding-left: 14%;
  font-size: small;
}

.error-message-cost {
  color: #ff1f1f;
  padding-left: 14%;
  font-size: small;
}

.field-dropdown {
  border-radius: 30px !important;
  padding-left: 10px !important;
  max-width: 280px !important;
  height: 40px !important;
  border-color: #c1c1c1;
  font-family: 'Manrope', sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
}

.new-reward-question {
  color: white !important;
}
