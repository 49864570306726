#kpi-list {
  padding: 0;
  /* background-color: rgb(72, 72, 72); */
  border: 1px solid black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
#title-box {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding: 15px;
  /* background-color: rgb(72, 72, 72); */
  border: 1px solid black;
  border-radius: 15px;
}
.label-list-item {
  margin: 0;
  background-color: rgb(234, 234, 234);
  border-bottom: 1px solid lightgray;
  /* border: 2px solid red; */
}
.rule-list-item {
  /* background-color: red; */
  border-bottom: 1px solid gray;
}
.rule-list-item:hover {
  /* background-color: rgb(92, 92, 92); */
  background-color: #f5dcff26;
  cursor: pointer;
}
#create-backdrop-box {
  max-width: 80vw;
  min-width: 50vw;
  max-height: 85vh;
  /* width: 80vw; */
  /* height: 90vh; */
  border-radius: 15px;
  background-color: white;
  margin-top: 100px;
  padding: 20px;
  position: relative;
  overflow-y: scroll;
}
/* @media (max-width: 1440px) {
  #create-backdrop-box {
    overflow-y: scroll;
  }
} */
.backdrop-close-button {
  font-weight: bold;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 10px;
  z-index: 1;
  right: 8px;
  top: 8px;
  width: 38px;
  height: 38px;
  background-color: #d04343;
  border: 1px solid white;
  transition: 0.1s ease-in all;
}
.backdrop-close-button:hover {
  cursor: pointer;
  background-color: red;
}
#formula-button {
  background-color: #5c5bb4;
  border: 1px solid white;
  color: white;
  margin: 0 5px;
  padding: 3px;
  font-weight: bold;
  border-radius: 40px;
  transition: 0.3s ease-in-out all;
}
#formula-button:hover {
  background-color: var(--application-color-active);
  border: 1px solid #5c5bb4;
  color: #5c5bb4;
}
.single-switch-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#select-inputs-box {
  width: 50%;
}
#dimenssions-box {
  margin-top: 25px;
  padding: 15px;
  width: 100%;
  height: fit-content;
  border: 1px solid lightgray;
  border-radius: 15px;
}
#formula-wizard-box {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  position: relative;
  width: 60vw;
  height: fit-content;
  border-radius: 15px;
}
.input-options-box {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  border: 1px solid lightgray;
  border-radius: 15px;
}

.op-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 5px;
  padding: 5px 15px;
  min-width: 50px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}
.math-option {
  background-color: rgb(100, 161, 116);
  font-size: 1.5rem;
}
.comp-option {
  background-color: rgb(100, 118, 161);
  font-size: 1rem;
}
.sub-option {
  background-color: rgb(208, 108, 108);
  font-size: 1rem;
}
.disabled-op {
  background-color: rgb(111, 111, 111);
  font-size: 1rem;
}
.formula-rules-box {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
}
.flex-center {
  align-items: center;
}

.select-option {
  background-color: white;
  color: 'red';
}
.door-data-menu-item:hover {
  background-color: red;
}
