#list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: gray;
  padding: 15px;
}
.w100 {
  width: 100%;
}
.center-content {
  display: flex;
  justify-content: center;
  margin: 10px;
}
#entitlements-box {
  /* background-color: rgb(72, 72, 72); */
  border-radius: 15px;
  border: 1px solid black;
  margin-top: 10px;
}

#pin-dropping-box {
  /* background-color: rgb(72, 72, 72); */
  border: 1px solid black;
  padding: 5px 15px;
  margin: 5px 0;
  border-radius: 15px;
}
#feed-features-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 5px 0;
  border-radius: 5px;
}
#feed-box {
  /* background-color: rgb(72, 72, 72); */
  border: 1px solid black;
  padding: 5px 15px;
  margin: 5px 0;
  border-radius: 15px;
}

#feedblast-box {
  /* background-color: rgb(72, 72, 72); */
  border: 1px solid black;
  padding: 5px 15px;
  margin: 5px 0;
  border-radius: 15px;
}
.space-b {
  justify-content: space-between;
}
.g-hov {
  transition: all 0.2s ease-in-out;
}
.g-hov:hover {
  background-color: rgb(245, 245, 245);
}
#general-info-box {
  /* background-color: rgb(51, 51, 51); */
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  border-radius: 15px;
}
#pins-box {
  background-color: rgb(240, 229, 246);
  display: flex;
  padding: 10px;
  /* align-items: center;
  justify-content: center; */
  border-radius: 15px;
  flex-wrap: wrap;
}
.single-pin-box {
  box-sizing: border-box;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#entitlements-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 40vh;
  overflow-y: scroll;
}
.org-tile {
  border-bottom: 1px solid rgb(255, 255, 255);
  margin-top: 2px;
  border: 1px solid lightgray;
  padding-top: 5px;
  transition: 0.3s ease-in-out;
  /* background-color: rgb(0, 0, 0); */
}
.org-tile:hover {
  cursor: pointer;
  /* background-color: rgba(128, 128, 128, 0.437); */
  background-color: rgba(94, 151, 177, 0.437);
  color: rgb(255, 255, 255);
}
.config-label {
  color: white;
}
#single-switch-features {
  display: flex;
  color: white;
  gap: 2vw;
  margin-top: 15px;
  justify-content: space-between;
  /* background-color: aquamarine; */
}
.single-switch-feature-box {
  padding: 4px 15px;
  border-radius: 15px;
  margin: 3px;
  border: 1px solid black;
  /* background-color: rgb(72, 72, 72); */
  width: 14vw;
}

.grayscale-image {
  filter: grayscale(100%);
  /* filter: invert(1); */
}
.hov:hover {
  cursor: pointer;
}
.backdrop-box {
  width: 40vw;
  /* height: 50vh; */
  background-color: white;
  border-radius: 15px;
  padding: 15px;
}
#features-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.multi-att-feature-box {
  /* background-color: rgb(72, 72, 72); */
  padding: 5px 15px;
  /* margin: 5px 0; */
  border: 1px solid black;
  border-radius: 15px;
}
.add-pin-box {
  background-color: white;
  font-size: 3rem;
  font-weight: bold;
  width: 80px;
  height: 80px;
  margin: 5px;
  border-radius: 15px;
  transition: 0.15s all ease-in;
}
.add-pin-box:hover {
  cursor: pointer;
  background-color: rgb(84, 78, 78);
  color: white;
}
.select-img {
  width: 60px;
  height: 60px;
}
.select-img:hover {
  cursor: pointer;
}
.flex-centered {
  display: flex;
  align-items: center;
}
.config-select-field {
  margin-left: 5px;
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  padding: 0;
  background-color: red;
}
.w60 {
  width: 60%;
}
#bottom-save-buttons-box {
  display: flex;
  /* justify-content: space-between; */
  padding: 15px;
}
.save-changes-box {
  background-color: white;
  position: fixed;
  display: flex;
  right: 50px;
  top: 9vh;
  z-index: 5;
  padding: 10px;
  /* padding: 5px; */
  border-radius: 5px;
  border: 2px solid lightblue;
}
.save-changes-box > * {
  margin: 3px;
}
.slide-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

.slide-exit {
  transform: translateY(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}
#org-search-input {
  padding: 5px 10px;
  border-radius: 5px;
}
.create-default-schedule-button {
  background-color: white;
  color: #ff6a00;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  border-radius: 8px;
  border: 1px solid #ff6a00;
  transition: 0.2s all ease-in-out;
  /* font-weight: bold; */
  /* margin: 15; */
}
.create-default-schedule-button:hover {
  cursor: pointer;
  background-color: #ff6a00;
  color: white;
}
.default-schedule-day-single-box {
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid white;
}
.default-schedule-day-single-box:hover {
  border: 1px solid #fac8b2;
  cursor: pointer;
}
.selected-single-day {
  background-color: #ff6a00;
  color: white;
}
.unselected-single-day {
  background-color: #c6c6c6;
  color: gray;
}
.white-bg {
  background-color: white;
}

#roles-buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
  background-color: lightblue;
  padding: 5px;
  margin-bottom: 5px;
}
