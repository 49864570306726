.team-form-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.team-form-modal-container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  width: 100%;
  height: fit-content;
}

.team-form-page-top-container {
  display: flex;
  position: absolute;
  z-index: -1;
  background-color: #f0f0f3;
  height: 346px;
  width: 100%;
}

.team-form-modal-top-container {
  display: flex;
  position: absolute;
  z-index: -1;
  height: 346px;
  width: 100%;
}

.team-form-page-cancel-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 124px 60px 0px;
}

.team-form-modal-cancel-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}

.MuiButton-text.team-form-page-cancel-button {
  position: relative;
  z-index: 2; /*to be above team-form-page-container*/
}

.MuiButton-text.team-form-page-cancel-button:hover {
  cursor: pointer;
  background-color: transparent;
}

.MuiButton-text.team-form-modal-cancel-button {
  position: relative;
  z-index: 2; /*to be above team-form-page-container*/
  margin-left: 16px;
}

.MuiButton-text.team-form-modal-cancel-button:hover {
  cursor: pointer;
  background-color: transparent;
}

.MuiTypography-body1.team-form-page-cancel-button-text {
  color: #0b0b0c;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-left: 16px;
}

.MuiTypography-body1.team-form-modal-cancel-button-text {
  color: #0b0b0c;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
}

.team-form-page-title-container {
  width: 708px;
}

.team-form-modal-title-container {
  width: 708px;
  margin-top: 52px;
}

.team-form-place-holder-container {
  width: 110px; /*match width of cancel button*/
}

.MuiTypography-body1.team-form-title-text {
  color: #222428;
  /* font-size: 36px; */
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
}

.page-team-form-container {
  display: flex;
  flex-direction: column;
  width: 708px;
  margin-top: 216px;
  padding-bottom: 60px;
}

.modal-team-form-container {
  display: flex;
  flex-direction: column;
  width: 708px;
  margin-top: 128px;
  padding-bottom: 60px;
}

.MuiTypography-body1.team-form-text {
  display: flex;
}

.team-form-input:before,
.team-form-input:after,
.error-team-form-input:before,
.error-team-form-input:after {
  border: 0 !important;
}

.error-team-form-input {
  /* border: 1px #ff5c77 solid !important; */
  border: 1px #e35050 solid !important;
}

.flex-field-images-section {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 16px 0px 16px;
}

.MuiInputBase-root.flex-field-select {
  border-radius: 8px;
}

#mui-component-select-owner,
#mui-component-select-tangoAccountID {
  border: none;
  padding: 16px 32px 17px 16px;
}

.save-team-button-container {
  display: flex;
  justify-content: flex-end;
}

.MuiButton-text.save-team-button {
  background-color: #ff6a00;
  width: 228px;
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
}

.skeleton-save-team-button {
  display: flex;
  justify-content: center;
  background-color: #ff6a00;
  width: 228px;
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
}

.MuiButton-text.save-team-button:hover {
  cursor: pointer;
  background-color: #ff6a00;
}

.MuiTypography-body1.save-team-button-text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}

/* Cancel Deal Form Modal */

.cancel-team-form-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  width: 624px;
  height: 236px;
  padding: 40px 40px 32px 40px;
  border-radius: 8px;
}

.MuiTypography-body1.cancel-team-form-modal-top-text {
  color: #222428;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}

.MuiTypography-body1.cancel-team-form-modal-bottom-text {
  color: #868686;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.cancel-team-form-modal-bottom-container {
  display: flex;
  justify-content: space-between;
}

.MuiButton-text.leave-button,
.MuiButton-text.leave-button:hover {
  background-color: transparent;
  border: 1px #cfcfcf solid;
}

.MuiButton-text.keep-editing-button,
.MuiButton-text.keep-editing-button:hover {
  background-color: #ff6a00;
}

.MuiButton-text.leave-button,
.MuiButton-text.leave-button:hover,
.MuiButton-text.keep-editing-button,
.MuiButton-text.keep-editing-button:hover {
  width: 262px;
  height: 52px;
  border-radius: 8px;
}

.MuiTypography-body1.keep-editing-button-text,
.MuiTypography-body1.leave-button-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.MuiTypography-body1.leave-button-text {
  color: #323232;
}

.MuiTypography-body1.keep-editing-button-text {
  color: #ffffff;
}

/* Form Inputs */
.MuiInputBase-root.MuiInputBase-adornedEnd:has(input#form-date-picker) {
  padding-right: 16px !important;
}
