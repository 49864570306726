.categories_selection_container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.category_selection_button {
  border: 1px solid lightgray;
  padding: 8px 10px;
  min-width: 150px;
  border-radius: 6px;
}

.category_selection_button_text {
  font-weight: 700;
  font-size: large;
}

.category_general_info_container {
  margin: 10px 0px;
  padding: 15px;
  border: 1px solid black;
}
.square-select > fieldset {
  border-radius: 4px !important;
  /* border: '1px solid red'; */
}
.stage_form_selector {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-bottom: none;
}
.create_form_container {
  /* margin-bottom: 16px; */
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid lightgray;
}
