/* Profile Stats Page */
.MuiButton-text.profile-stats-page-back-button,
.MuiButton-text.profile-stats-page-back-button:hover {
  background-color: transparent;
}

.profile-stats-page-section-button {
  color: #ff6a00;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-right: 25px;
  padding-bottom: 10px;
}

#stats-subpage {
  display: flex;
  flex-wrap: wrap;
  /* padding-top: 15px; */
  padding-bottom: 15px;
  border-radius: 8px;
}

#reward-list,
#appointment-list {
  display: flex;
  flex-direction: column;
}

/* .profile-stats-member-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f3;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 5px;
} */

.reward-list-item-left-container,
.appointment-list-item-left-container {
  display: flex;
}

.reward-list-item-left-text-container,
.appointment-list-item-left-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .profile-stats-member-list-item-right-container,
.linked-profile-stats-list-item-right-container {
  position: relative;
  height: 45px;
  display: flex;
  align-items: flex-start;
} */

.MuiButton-text.reward-option-button,
.MuiButton-text.appointment-option-button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 2000px;
  margin-left: 10px;
}

.MuiButton-text.reward-option-button,
.MuiButton-text.reward-option-button:hover,
.MuiButton-text.appointment-option-button,
.MuiButton-text.appointment-option-button:hover {
  background-color: transparent;
}

/* .rewards-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f3;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 5px;
} */

.rewards-list-item-left-container,
.appointments-list-item-left-container {
  display: flex;
}

.rewards-list-item-left-text-container,
.appointments-list-item-left-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .rewards-list-item-right-container,
.appointments-list-item-right-container {
  position: relative;
  height: 45px;
  display: flex;
  align-items: flex-start;
} */

.MuiButton-text.rewards-option-button,
.MuiButton-text.appointments-option-button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 2000px;
  margin-left: 10px;
}

.MuiButton-text.rewards-option-button,
.MuiButton-text.rewards-option-button:hover,
.MuiButton-text.appointments-option-button,
.MuiButton-text.appointments-option-button:hover {
  background-color: transparent;
}
